<script>
    import { kyc, fetching, error, payment } from '$lib/stores'
    import { TYPE_IFRAME, TYPE_REDIRECT } from '$lib/stores/kyc'
    import { _ } from 'svelte-i18n'
    import IconX from '$lib/images/icons/icon_X.png'
    import { log } from '$lib/utilities/errorTrackingTools.js'
    import { inIFrame } from '$lib/utilities/iFrameUtilities.js'
    import { goto } from '$app/navigation'
    import { page } from '$app/stores'
    import { onMount } from 'svelte'
    import { get } from 'lodash-es'
    import LoadingSpinner from '$lib/Components/LoadingSpinner.svelte'
    import Cancel from '$lib/Components/Payment/Buttons/Cancel.svelte'
    import { faUserShield } from '@fortawesome/free-solid-svg-icons'
    import Fa from 'svelte-fa'

    // Define a variable for storing the kyc window
    let kycWindow = null

    let startKycClicked = false

    $: kycProcessLoading = get($fetching, 'kyc_process.fetching', true)
    $: iFrameLoading = $fetching.kyc_iframe?.fetching

    const tryAgainHandler = () => {
        kyc.retryKyc()
        kyc.fetchKycProcess($payment.uuid)
    }

    const handleIframeTask = e => {
        if (e.data.kycSuccess !== undefined) {
            log({
                message: `Authentication iframe passed a message, kycSuccess was ${e.data.kycSuccess}.`,
                category: 'kyc',
            })
            e.data.kycSuccess ? kyc.setKycCompleted() : kyc.setKycFailed()
        }
    }

    const kycContinueHandler = () => {
        // Handle the kyc by type
        switch ($kyc.type) {
            // On a redirect KYC, the user needs to be redirected to another service for the KYC.
            case TYPE_REDIRECT:
                // If the payment page is currently opened in an iframe, we need to open the kyc to a new window
                if (inIFrame()) {
                    // Define receiver function to window, which is called from the kycWindow. After the KYC
                    // is completed in the 3rd party service, the user is redirected to a small blank page
                    // with js. This page calls our receiver function on this page, which handles the result.
                    window.kycIsReadyReceiver = (kycSuccess, code = '') => {
                        log({
                            message: `kycIsReadyReceiver called from kycWindow. Success: ${kycSuccess} Code: ${code}.`,
                            category: 'kyc',
                        })
                        // Close the kyc window
                        kycWindow?.close?.()
                        // Dispatch success for a completed kyc, or display failed-message for the user to try again
                        if (kycSuccess && code) {
                            $page.url.searchParams.append('code', code)
                            goto(`/teliareturn?${$page.url.searchParams.toString()}`)
                        } else {
                            kyc.setKycFailed()
                        }
                    }

                    // Open the kyc flow in a new window
                    log({ message: `Opening kyc to a new window.`, category: 'kyc' })
                    kycWindow = window.open($kyc.url, '_blank')
                } else {
                    // If we are not currently in an iframe, we just need to redirect the user to the kyc flow.
                    window.location.assign($kyc.url)
                }
                break
            case TYPE_IFRAME:
                fetching.startFetching('kyc_iframe')
                // If this kyc happens here in an iframe, just indicate that user wants to start the kyc
                startKycClicked = true
                break
            default:
        }
    }

    onMount(async () => {
        try {
            fetching.startFetching('kyc_process')
            await kyc.fetchKycProcess($payment.uuid)
        } catch (e) {
            error.set({ code: 'kyc_process_fetch_failed' })
        }
        fetching.cancelFetching('kyc_process')

        window.addEventListener('message', handleIframeTask)
        return () => {
            window.removeEventListener('message', handleIframeTask)
        }
    })
</script>

{#if $kyc.failed}
    <div>
        <div class="mb-4 pt-3">
            <img src={IconX} aria-hidden="true" alt="Error icon" />
        </div>
        <h1 class="mb-3">
            {$_('payment.kyc.title.kyc_failed')}
        </h1>
        <p class="mb-5">
            {$_('payment.kyc.warn.kyc_failed')}
        </p>
        <div class="d-grid gap-2">
            <button class="btn btn-primary btn-lg mb-2" on:click={tryAgainHandler}>
                {$_('error.button.try_again')}
            </button>
        </div>
    </div>
{:else}
    {#if kycProcessLoading || iFrameLoading}
        <LoadingSpinner />
    {:else if !kycProcessLoading && !startKycClicked}
        <div class="pt-4 pb-4 mb-2">
            <Fa icon={faUserShield} size="5x" class="text-primary" />
        </div>
        <h1>
            {$_('payment.kyc.begin_kyc_instructions.title')}
        </h1>
        <p>
            {$_('payment.kyc.begin_kyc_instructions.p')}
        </p>
        <div class="d-grid gap-2">
            <button on:click={kycContinueHandler} class="btn btn-primary btn-lg mb-3">
                {$_('payment.kyc.begin_kyc_instructions.continue')}</button
            >
        </div>
    {/if}

    {#if startKycClicked && $kyc.type === TYPE_IFRAME}
        <iframe
            title="Identity verification"
            src={$kyc.url}
            class={iFrameLoading ? 'd-none' : null}
            on:load={() => fetching.cancelFetching(`kyc_iframe`)}
        />
    {/if}

    <Cancel />
{/if}

<style>
    img {
        height: 80px;
    }

    iframe {
        width: 100%;
        height: 725px;
        border: 0;
    }

    @media (max-width: 402px) {
        iframe {
            margin-left: -25px;
            width: 114%;
        }
    }
</style>
