import SiruAxios from '$lib/SiruAxios'

export const askForLocation = uuid => {
    if (!navigator.geolocation) return

    navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
            const location = {
                accuracy: '',
                altitude: '',
                altitudeAccuracy: '',
                heading: '',
                latitude: '',
                longitude: '',
                speed: '',
            }

            // Why not just loop the coords? Because the Coordinates interface is not iterable
            for (const key of Object.keys(location)) {
                if (coords[key]) location[key] = String(coords[key])
            }
            SiruAxios.post(
                `pay/rest/${uuid}/geolocation`,
                {
                    location,
                },
                {
                    useGlobalErrorHandler: false,
                },
            )
        },
        () => {},
        {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 180000,
        },
    )
}
