import { browser } from '$app/environment'
import type { LayoutLoad } from './$types'
import { error } from '@sveltejs/kit'
import { waitLocale } from 'svelte-i18n'
import { fetching, fingerprint, payment } from '$lib/stores'
import { askForLocation } from '$lib/utilities/geolocation'
import SiruAxios from '$lib/SiruAxios'
import { AxiosError } from 'axios'
import { get } from 'svelte/store'
import { walletPaymentAuthentications, error as errorStore } from '$lib/stores'
import { captureException } from '$lib/utilities/errorTrackingTools'

export const load: LayoutLoad = async ({ url }) => {
    if (browser) {
        const uuid = url.searchParams.get('uuid')

        if (!uuid) {
            error(404)
        }

        if (!get(fetching).payment?.fetched) {
            fetching.startFetching('payment')
            payment
                .fetch(uuid)
                .then(() => {
                    fetching.cancelFetching('payment')
                    fetching.setFetched('payment')

                    fingerprint.createAndSend(uuid)
                    askForLocation(uuid)

                    // Check if we already have an authentication for this payment
                    const walletPaymentAuthenticationsValue = get(walletPaymentAuthentications)
                    if (walletPaymentAuthenticationsValue[uuid]) {
                        SiruAxios.defaults.headers.common['Authorization'] =
                            `Bearer ${walletPaymentAuthenticationsValue[uuid]}`
                    }
                })
                .catch(e => {
                    console.error(e)
                    if ((e as AxiosError).response?.status === 404) {
                        errorStore.set({ status: 404 })
                    } else {
                        captureException(e)
                        errorStore.set({ status: 500 })
                    }
                })
        }
    }

    await waitLocale()
}

export const prerender = true
