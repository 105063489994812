<script>
    import { get } from 'lodash-es'
    import { error, fetching, kyc, numberConfirmed, payment } from '$lib/stores'
    import LoadingSpinner from '$lib/Components/LoadingSpinner.svelte'
    import { isFinal } from '$lib/utilities/statusCodes'
    import { browser } from '$app/environment'
    import { navigating } from '$app/stores'
    import Kyc from '$lib/Components/Kyc/Kyc.svelte'
    import { fade } from 'svelte/transition'

    $: loading = get($fetching, `payment.fetching`, true)

    let poller

    $: {
        if (browser && !poller && $numberConfirmed) {
            poller = setInterval(async () => {
                if (!$fetching.poll?.fetching && !isFinal($payment.status) && !$error) {
                    fetching.startFetching('poll')
                    await payment.doPoll()
                    fetching.cancelFetching('poll')
                }
            }, 2_000)
        }
    }

    $: {
        if (isFinal($payment.status) || $error) {
            clearInterval(poller)
            poller = null
        }
    }
</script>

{#if loading || $navigating}
    <LoadingSpinner />
{:else if $kyc.required && !$kyc.completed}
    <Kyc />
{:else}
    <div in:fade={{ duration: 150 }}>
        <slot />
    </div>
{/if}
